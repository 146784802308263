.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.RMSideBar .SidebarButtons {
  display: none;
}

.RMApplicationHeader .dropdown {
  width: 132px;
  pointer-events: none;
}

.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.login .logo {
  max-width: 20rem;
  margin-bottom: 4rem;
}
.login .logo img {
  max-width: 100%;
  height: auto;
}

.auth .module-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 799px) {
  .login .module-content {
      display: flex;
      min-width: 0;
  }
}
.login .login-box {
  position: relative;
}
@media screen and (min-width: 800px) {
  .login .login-box {
      min-width: 60rem;
  }
}

tbody {
  word-break: break-word;
}

.pagination-wrapper--center {
  display: inline-flex;
  flex: 1;
  justify-content: center;
}

.breakline {
  border-top: 1px solid #697a8b;
  width: 100%;
  height: 1px;
}

.messageDetailHeading {
  cursor: pointer;
}

.messageDialog {
  overflow: hidden
}

.margin-bottom-85 {
  margin-bottom: 85px !important
}

.margin-bottom-95 {
  margin-bottom: 95px !important
}

.margin-bottom-70 {
  margin-bottom: 70px !important
}

.margin-left-70 {
  margin-left: 70px !important
}

.margin-top-100 {
  margin-top: 100px !important
}

.message-creation-form-button .btn-default:first-child {
  width: 200px !important;
}

.message-creation-footer .btn {
width: 80px !important
}

#message-creation-textarea {
  border-color: rgb(92, 92, 92);
}

/* #message-creation-textarea:focus, #message-creation-textarea:active {
  border-width: 2px;
  border-color: black;

} */

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
    /* border-width: 2px; */
    /* border-color: black; */
}

.popover-message-creation {
  z-index: 9999;
}

.pointer-events-none {
  pointer-events: none;
}

.recharts-wrapper{
  position: absolute !important
}

.padding-top-6 {
padding-top:6px
}

.padding-bottom-6 {
  padding-bottom:6px
  }